//Checks if pendo window is started in order to initialize it
 function initPendo() {
   intervalCounter++;
   if (!window['pendo'] || !window['pendo'].validateInstall) {
      console.log('Pendo not available: Init Failed EZT-TEST-BUILDER');
      if (intervalCounter === 30) {
                //pendo initialization failed after trying for 30 seconds
                clearInterval(loadInterval);
       }
       return;
   }
   //clearInterval(loadInterval);
   if (window['pendo'] && !pendoInitialized) {
      console.log('initializing Pendo EZT-TEST-BUILDER');
      window['pendo'].initialize({
      visitor: visitorParams,
      account: {
            id: orgXid
      },
      disableGuides: false
    });
	if((window['pendo']) && (window['pendo'].validateInstall) && (window['pendo'].validateEnvironment) && !pendoInitialized) {
		console.log("Pendo successfully initialized EZT-TEST-BUILDER");
		pendoInitialized = true;
		clearInterval(loadInterval);
	}
   }
 }
function getCookie(cookieName) {
  var name = cookieName + "=";
  var cookies = document.cookie.split(';');
  for(var i = 0; i < cookies.length; i++) {
    var cookieData = cookies[i];
    while (cookieData.charAt(0) === ' ') {
      cookieData = cookieData.substring(1);
    }
    if (cookieData.indexOf(name) === 0) {
      return cookieData.substring(name.length, cookieData.length);
    }
  }
  return "";
}

function checkForCookie(key) {
  var value = ''
  var cookies = document.cookie.split(';');
  for(var i = 0; i < cookies.length; i++) {
    var cookieData = cookies[i];
    if(cookieData.includes(key)) {
        value = cookieData.split('=')[1];
        break;
     }
  }
  return value;
}

function formatDataCookie(userObj){
  var param = '';
  param = (userObj.visitormetadata.agent__personxid == null ? "NA" : userObj.visitormetadata.agent__personxid)  + '::' + 'NA::' + (userObj.visitormetadata.agent__orgid == null ? 'NA' : userObj.visitormetadata.agent__orgid) + '::' + 'EZT-TEST-BUILDER' + '::' + 'student' + '::' + (userObj.visitormetadata.agent__mh_orgcountry == null ? 'NA' : userObj.visitormetadata.agent__mh_orgcountry) + '::' + (userObj.visitormetadata.agent__mh_coursedescriptor == null ? 'NA' : userObj.visitormetadata.agent__mh_coursedescriptor) + '::';
  console.log('formatDataCookie');
  console.log('[' + param + ']');
 return param;

}

//Adds script to the application registering the pendo's key
function loadPendo() {
	if (params) { console.log('CONNECT_PENDO EZT-TEST-BUILDER  - data (' + params + ')'); }
    if (params !== '') {
        // break by '::'
        params = params.split('::');

        //when we have all params
        if (params.length > 5) {
            orgXid = (params[2]) ? params[2] : 'NA';
            userXid = (params[0]) ? params[0] : 'NA';
            userRole = (params[4]) ? params[4] : 'NA';
            connectLanguage = (params[5]) ? params[5] : 'NA';
            userType = params[6] || '';
            visitorParams = {
                id: userXid,
                environment:  (params[1]) ? params[1] : 'NA',
                org: orgXid,
                platform: "EZT TEST BUILDER",
                role: userRole,
                connectLanguage: connectLanguage,
                mh_courseDescriptor: userType
            };
        }
    }
    // send user_type only for some roles
    if (userType !== '') {
      visitorParams.user_type = userType;
    }
    // only if required data is available
    if (orgXid !== '' && userXid !== '' && !pendoInitialized) {
        console.log('calling initPendo EZT TEST BUILDER');
        loadInterval = setInterval(function() {initPendo()}, 1000);
    }
}

  var loadInterval = null;
  var intervalCounter = 0;
  // read cookie value
  var params = unescape(getCookie('CONNECT_PENDO'));
  if (params == '') {
	var cookieRead = unescape(checkForCookie('_pendo___sg__'));
	console.log('['+ cookieRead + ']');
	if (cookieRead){
		var userObj = JSON.parse(cookieRead);
		console.log(userObj);
		params = formatDataCookie(userObj);
	}
  }
  var pendoInitialized = false;
  var orgXid = '';
  var userXid = '';
  var userRole = '';
  var connectLanguage = '';
  var userType = 'Instructor';
  var visitorParams = {};

document.onload = loadPendo();
